d<template>
  <div id="analytics">
    <!-- restricted to SUPER ADMIN -->
    <template v-if="isSuperAdmin">

      <div class="flex w-full align-baseline">
        <div class="flex w-1/3">
          <p class="mt-2">{{ $t('scale') }} :</p>
          <v-select v-model="scale" :options="scales" :clearable="false" />
        </div>
        <div class="flex w-1/3">
          <p class="mt-2">{{ $t('startDate') }} :</p>
          <datepicker placeholder="Start Date" v-model="start" format="yyyy-MM-dd"></datepicker>
        </div>
        <div class="flex w-1/3">
          <p class="mt-2">{{ $t('endDate') }} :</p>
          <datepicker placeholder="End Date" v-model="end" format="yyyy-MM-dd"></datepicker>
        </div>
      </div>

      <!-- Cumulated number of users -->
      <div class="vx-col w-full mb-base" v-if="this.usersCumulatedChart.ready" :key="pageVersion">
        <vx-card 
          :title="`${$t('analyticsRegisteredUsers')}`">
            <vue-apex-charts 
              type="area" 
              :height="height" 
              :options="this.usersCumulatedChart.chartOptions" 
              :series="this.usersCumulatedChart.series">
            </vue-apex-charts>
        </vx-card>
      </div>

      <!-- Cumulated number of layers -->
      <div class="vx-col w-full mb-base" v-if="this.layersCumulatedChart.ready" :key="pageVersion">
        <vx-card 
          :title="`${$t('analyticsHostedLayers')}`">
            <vue-apex-charts 
              type="area" 
              :height="height" 
              :options="this.layersCumulatedChart.chartOptions" 
              :series="this.layersCumulatedChart.series">
            </vue-apex-charts>
        </vx-card>
      </div>
      
      <!-- Unique users count -->
      <div class="vx-col w-full mb-base" v-if="this.usersChart.ready" :key="pageVersion">
        <vx-card 
          :title="`${$t('analyticsActiveUsers')} ${$t('by')} ${$t(this.scale)}`">
            <vue-apex-charts 
              type="area" 
              :height="height" 
              :options="this.usersChart.chartOptions" 
              :series="this.usersChart.series">
            </vue-apex-charts>
        </vx-card>
      </div>

      <!-- Conversions count -->
      <div class="vx-col w-full mb-base" v-if="this.conversionsChart.ready" :key="pageVersion">
        <vx-card 
          :title="`${$t('analyticsConversions')} ${$t('by')} ${$t(this.scale)}`">
            <vue-apex-charts 
              type="area" 
              :height="height" 
              :options="this.conversionsChart.chartOptions" 
              :series="this.conversionsChart.series">
            </vue-apex-charts>
        </vx-card>
      </div>

    </template>
    <template v-else>
        <br>restricted area
    </template>
  </div>
</template>
<script>
import axios from '@/axios.js'
import vSelect from 'vue-select'
import moment from 'moment'
import VueApexCharts from 'vue-apexcharts'
import apexChatData from 'apexcharts'

import Datepicker from 'vuejs-datepicker'

export default {
  components: {
    moment,
    VueApexCharts, 
    vSelect, 
    Datepicker
  },
  data () {
    return {
      apexChatData,
      api_server_baseurl: this.$appConfig.apiBaseUrl,
      appConfig: this.$appConfig,
      getNumberOfUsersByPeriod: 'getNumberOfUsersByPeriod', 
      getNumberOfConversionsByPeriod: 'getNumberOfConversionsByPeriod', 
      getCumulatedNumberOfUsers: 'getCumulatedNumberOfUsers', 
      getCumulatedNumberOfLayers: 'getCumulatedNumberOfLayers', 

      // RAW results
      users: [],
      usersCumulated: [],
      layersCumulated: [],
      conversions: [],

      // general charts config
      scales: ['day', 'week', 'month', 'year'],
      scale: 'week',
      start: '2020-10-01',
      end: new Date().toISOString().split('T')[0],
      pageVersion: 'firstKey',
      height: 400,

      // by chart config
      usersChart: {
        ready: false,
        series: [
          {
            name: 'Users',
            data: []
          }
        ],
        chartOptions: {
          chart: {
            height: 500,
            zoom: {
              enabled: false
            }
          },
          colors: ['#8bb517', '#28C76F', '#EA5455', '#FF9F43', '#1E1E1E'],
          dataLabels: {
            enabled: true
          },
          stroke: {
            curve: 'smooth'
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            }
          },
          xaxis: {
            categories: []
          }
        }
      },

      conversionsChart: {
        ready: false,
        series: [
          {
            name: 'Conversions',
            data: []
          }
        ],
        chartOptions: {
          chart: {
            height: 500,
            zoom: {
              enabled: false
            }
          },
          colors: ['#8bb517', '#28C76F', '#EA5455', '#FF9F43', '#1E1E1E'],
          dataLabels: {
            enabled: true
          },
          stroke: {
            curve: 'smooth'
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            }
          },
          xaxis: {
            categories: []
          }
        }
      },

      usersCumulatedChart: {
        ready: false,
        series: [
          {
            name: 'Registered users',
            data: []
          }
        ],
        chartOptions: {
          chart: {
            height: 500,
            zoom: {
              enabled: false
            }
          },
          colors: ['#8bb517', '#28C76F', '#EA5455', '#FF9F43', '#1E1E1E'],
          dataLabels: {
            enabled: true
          },
          stroke: {
            curve: 'smooth'
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            }
          },
          xaxis: {
            categories: []
          }
        }
      }, 

      layersCumulatedChart:{
        ready: false,
        series: [
          {
            name: 'Layers',
            data: []
          }
        ],
        chartOptions: {
          chart: {
            height: 500,
            zoom: {
              enabled: false
            }
          },
          colors: ['#8bb517', '#28C76F', '#EA5455', '#FF9F43', '#1E1E1E'],
          dataLabels: {
            enabled: true
          },
          stroke: {
            curve: 'smooth'
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            }
          },
          xaxis: {
            categories: []
          }
        }
      }
    }
  },
  methods: {   
    refreshAll () {
      this.getTotalUsers()
      this.getTotalConversions()
      this.getUsersEvolution()
      this.getLayersEvolution()
    },
    getTotalUsers () {
      const params = `start=${this.startDate}&end=${this.endDate}&level=${this.scale}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/admin/analytics/${this.getNumberOfUsersByPeriod}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (response.data != null && response.data != 'null')  {
            this.usersChart.ready = false
            this.users = typeof response.data == 'object' ? response.data : null
            const values = this.getValues(this.users)
            const labels = this.getLabels(this.users)
            this.usersChart.series[0].data = values
            this.usersChart.chartOptions.xaxis.categories = labels
            this.pageVersion = (Math.random() + 1).toString(36).substring(7)
            this.usersChart.ready = true
          } else {
            this.users = []
          }
        })
        .catch((error)   => { console.log(error) })
    },
    getTotalConversions () {
      const params = `start=${this.startDate}&end=${this.endDate}&level=${this.scale}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/admin/analytics/${this.getNumberOfConversionsByPeriod}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (response.data != null && response.data != 'null')  {
            this.conversionsChart.ready = false
            this.conversions = typeof response.data == 'object' ? response.data : null
            const values = this.getValues(this.conversions)
            const labels = this.getLabels(this.conversions)
            this.conversionsChart.series[0].data = values
            this.conversionsChart.chartOptions.xaxis.categories = labels
            this.pageVersion = (Math.random() + 1).toString(36).substring(7)
            this.conversionsChart.ready = true
          } else {
            this.conversions = []
          }
        })
        .catch((error)   => { console.log(error) })
    },
    getUsersEvolution () {
      const params = `start=${this.startDate}&end=${this.endDate}&level=${this.scale}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/admin/analytics/${this.getCumulatedNumberOfUsers}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (response.data != null && response.data != 'null')  {
            this.usersCumulatedChart.ready = false
            this.usersCumulated = typeof response.data == 'object' ? response.data : null
            const values = this.getValues(this.usersCumulated)
            const labels = this.getLabels(this.usersCumulated)
            this.usersCumulatedChart.series[0].data = values
            this.usersCumulatedChart.chartOptions.xaxis.categories = labels
            this.pageVersion = (Math.random() + 1).toString(36).substring(7)
            this.usersCumulatedChart.ready = true
          } else {
            this.usersCumulated = []
          }
        })
        .catch((error)   => { console.log(error) })
    },
    getLayersEvolution () {
      const params = `start=${this.startDate}&end=${this.endDate}&level=${this.scale}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/admin/analytics/${this.getCumulatedNumberOfLayers}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (response.data != null && response.data != 'null')  {
            this.layersCumulated.ready = false
            this.layersCumulated = typeof response.data == 'object' ? response.data : null
            const values = this.getValues(this.layersCumulated)
            const labels = this.getLabels(this.layersCumulated)
            this.layersCumulatedChart.series[0].data = values
            this.layersCumulatedChart.chartOptions.xaxis.categories = labels
            this.pageVersion = (Math.random() + 1).toString(36).substring(7)
            this.layersCumulatedChart.ready = true
          } else {
            this.layersCumulated = []
          }
        })
        .catch((error)   => { console.log(error) })
    },
    getValues (serie) {
      return Object.entries(serie)
        .map(item => parseFloat(item[1].result))
    },
    getLabels (serie) {
      return Object.entries(serie)
        .map(function (item) {
          let label = Object.values(item[1])[0]
          const maxKey = Object.keys(item[1]).length - 2
          for (let i = 1; i <= maxKey; i++) {
            const val = `0${Object.values(item[1])[i]}`.slice(-2)
            label = `${label}.${val}`
          }
          return label
        })
    }
  },
 
  computed: {
    isSuperAdmin () {
      return typeof this.$store.state.current_user_global_caps.admin_caps == 'object' && 
        parseInt(this.$store.state.current_user_global_caps.admin_caps.can_global_manage) === 1
    },
    isCustomerServices () {
      return typeof this.$store.state.current_user_global_caps.admin_caps == 'object' && 
        parseInt(this.$store.state.current_user_global_caps.admin_caps.can_services_access) === 1
    },
    isGhost () {
      return typeof this.$store.state.current_user_global_caps.admin_caps == 'object' && 
        parseInt(this.$store.state.current_user_global_caps.admin_caps.is_ghost) === 1
    },
    startDate () {
      return new Date(this.start).toISOString().split('T')[0]
    },
    endDate () {
      return new Date(this.end).toISOString().split('T')[0]
    }
  },

  beforeMount () {
  },

  mounted () {
    document.body.classList.add('theme-dark')
    this.refreshAll()
  },

  watch: {
    scale () {
      this.refreshAll()
    },
    start () {
      this.refreshAll()
    },
    end () {
      this.refreshAll()
    }
  }
}

</script>
<style lang="scss">

.theme-dark input {
    background: none !important;
}
</style>
